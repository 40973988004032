import React from 'react';
import styled from 'styled-components';
import {
  SEO,
  AiCaseStudyHero,
  AiCaseStudyIntroduction,
  AiCaseStudyColumns,
  AiCaseStudyBlocks,
  AiCaseStudyTextGraph,
  AiCaseStudySideText,
  AiCaseStudyMarketerBlocks,
  AiCaseStudyBlockText,
  AiCaseStudyReferences,
  AiCaseStudyStartYourJourney
} from 'components';

const AiRevolution = () => {
  const primaryColumns = [
    {
      title: 'Generating operating efficiencies',
      text: "Gen AI hastens the research process by identifying drug targets quickly, driving sales and optimising supply chains. Deloitte's findings reveal that almost 70% of biopharmaceutical executives consider the adoption of gen AI essential for research and discovery."
    },
    {
      title: 'Assisting with patient-facing tasks',
      text: 'Automating administrative tasks, generating patient data summaries, enhancing diagnoses, and suggesting treatment options. By reducing time spent on repetitive tasks, clinicians will have more resources to focus on patient care and improving the user journey of patients. For example, medical chatbots can be utilised to deliver personalised information to patients.'
    }
  ];
  const secondaryColumns = [
    {
      title: 'Cost',
      text: 'Even though respondents (41%) were interested in educating themselves about their health (Edelman), almost 50% of consumers indicated cost as the biggest barrier to disease prevention (PwC). One in five patients (19%) have reported deferring care for their medical issue (McKinsey) because they believe that the Healthcare system is biased against their income.'
    },
    {
      title: 'Lack of information',
      text: 'Lack of information is a significant barrier to healthcare access for 48% of consumers (Edelman), and nearly a quarter, 23% (PwC), do not invest in disease prevention simply because they do not understand how prevention works. A total of 50% of respondents (Edelman) reported that the lack of information, changing health recommendations and contradictory expert advice were significant barriers to better health.'
    }
  ];
  return (
    <>
      <SEO
        pageSpecificTitle="The Ai Revolution: Redefining Healthcare Marketing"
        pageSpecificDescription="Harnessing the potential of Generative Ai"
        pageSpecificThumbnail="/thumbnails/aiCaseStudy/ai-marketing-social-banner.png"
        pageSpecificThumbnailAlt="The Ai Revolution: Redefining Healthcare Marketing"
      />
      <AiCaseStudyHero />
      <AiCaseStudyIntroduction />
      <AiCaseStudyColumns columns={primaryColumns} />
      <AiCaseStudyBlocks />
      <AiCaseStudyTextGraph />
      <AiCaseStudySideText />
      <AiCaseStudyColumns columns={secondaryColumns} />
      <AiCaseStudyMarketerBlocks />
      <AiCaseStudyBlockText />
      <AiCaseStudyReferences />
      <Section>
        <AiCaseStudyStartYourJourney />
      </Section>
    </>
  );
};

const Section = styled.section`
  width: 100%;
  height: auto;
  max-width: 90rem;
  padding: 2rem 0;
  margin: 0 auto;

  > div {
    grid-column: 2/3;
  }
  > h2 {
    color: var(--ax-gold);
    font-size: 1.75rem;
    grid-column: 2/3;
    margin-bottom: 3rem;
    text-align: center;
  }
  > p {
    font-size: 1.25rem;
    line-height: 1.3em;
    text-align: center;
    grid-column: 2/3;
    margin-bottom: 3rem;
  }
  @media (min-width: 48rem) {
    padding: 3rem 0 6rem 0;

    > h2 {
      font-size: 2rem;
    }
    > p {
      margin-bottom: 5rem;
    }
  }
`;

export default AiRevolution;
